import 'modern-css-reset';
import * as ReactDOM from 'react-dom';
import App from './App';

(() => {
  const root = document.createElement('div');
  root.id = 'root';
  root.style.minWidth = '100vw';
  root.style.minHeight = '100vh';
  document.querySelector('body')!.appendChild(root);
  ReactDOM.render(<App />, root);
})();
