import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function importedRouteComponent(source: () => Promise<{ default: React.ComponentType<any>; }>) {
  const Lazy = React.lazy(source);
  return () => (
    <React.Suspense fallback={<>loading...</>}>
      <Lazy />
    </React.Suspense>
  );
}

export default function App() {
  // <Route path="/login" exact component={importedRouteComponent(() => import(/* webpackChunkName: "LoginPage" */ './LoginPage'))} />
  // <Route path="/devicelink" exact component={importedRouteComponent(() => import(/* webpackChunkName: "DeviceLinkPage" */ './DeviceLinkPage'))} />
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={importedRouteComponent(() => import(/* webpackChunkName: "IndexPage" */ './IndexPage'))} />
          <Route path="*">
            <h1>not found</h1>
          </Route>
        </Switch>
      </Router>
    </>
  );
}
