import AppBar from '@material-ui/core/AppBar';

export default function IndexPage() {
  return (
    <>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#F2BB7B',
          padding: 10,
          flexDirection: 'row',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <div style={{ fontSize: 30, fontWeight: 'bold' }}>cloudprint</div>
        <div style={{ flex: 1 }} />
        <div style={{ fontSize: 15 }}>{require('../package.json').version}</div>
      </AppBar>
    </>
  );
};
